@import "./react-date-picker.scss";
@import "./listing-update.scss";
@import "./listing-sales.scss";
@import "./breakeven.scss";
@import "./channel-advisor.scss";
@import "./shipping-profile.scss";
@import "./EFC_DropdownMenu.scss";
@import "./business-report";
@import "./pre-launch.listing.scss";
@import "./permission.scss";
@import "./inbound-shipment";
@import "./expected-margin.scss";
@import "./modal.scss";
@import "./video.scss";
@import "./block-list.scss";

@import "./react-calendar.scss";
@import "./react-daterange-picker.scss";
@import "./inventory-allocation.scss";

.review-classification {
	&__rating {
		display: flex;
		margin: 6px 0;
		& > ul {
			display: flex;
			margin: 0 12px 0 0;
			padding: 0;
			list-style: none;
			& > li {
				margin-right: 2px;
			}
		}
	}
	&__line {
		padding: 4px;
	}
	&__title {
		font-size: 12px;
		margin: 0 0 4px 0;
		& > span {
			display: block;
			margin-top: 6px;
		}
	}
	&__content {
	}
	&__product-title {
		display: -webkit-box;
		overflow: hidden;
		-webkit-box-orient: vertical;
		max-width: 95%;

		// -webkit-line-clamp: 3;
	}

	&__panel {
		display: flex;
		// margin-top: 12px;
		padding-top: 12px;
		border-top: 1px solid #eee;
		& > div {
			margin-right: 24px;
			padding: 4px 0;
			& > label {
				font-weight: 500;
			}
			& > ul {
				margin: 0;
				padding: 0;
				& > li {
					display: flex;
					align-items: center;
					margin-bottom: 6px;
					padding: 2px;
					cursor: pointer;
					transition: background-color 0.2s ease-in-out;
					&:hover {
						background-color: #eee;
					}
					// justify-content: center;
					// flex-direction: column;
					& > input {
						margin: 0 6px 0 0;
						cursor: pointer;
					}
				}
			}
		}
	}

	&__remark {
		padding: 0 12px 0 !important;
		border-top: 0 !important;
		& > .form-group {
			margin-bottom: 8px;
		}
	}

	&__footer {
		& > div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 6px 12px !important;
		}
	}

	&__description-group {
		img {
			width: 100px;
		}
	}

	&__issue {
		margin: 4px 0;
		padding: 0;
		list-style: none;
		& > li {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-right: 12px;
			& > span {
				font-size: 12px;
				line-height: 18px;
			}
			& > i {
				line-height: 18px;
				margin-right: 2px;
				color: red;
			}
		}
	}

	&__user-remark {
		display: block;
		margin-bottom: 2px;
	}

	&__well {
		width: 80%;
		border-radius: 2px;
		background-color: transparent;
		box-shadow: none;
	}

	&__user {
		margin-bottom: 0;
		& > label {
			margin-bottom: 0;
		}
	}

	&__paginated {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		& > span {
			width: 100px;
		}
		& > .paginate {
			justify-content: flex-start !important;
			padding: 0 !important;
		}
	}

	&__paginated-action {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 40px;
	}
}

.table-checkbox {
	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
		& > input {
			margin: 0;
			margin-left: 6px;
		}
	}
}

.table-cell-input {
	& > input {
		border: 1px solid #ddd;
		border-radius: 2px;
		width: 100%;
		height: 100%;
		padding: 6px;
	}
}

.listing-detail__review-rating {
	ul {
		display: inline-flex;
		margin: 0;
		margin-right: 12px;
		padding: 0;
		list-style: none;
		li {
			margin-right: 2px;
		}
	}
	span {
		display: inline-block;
	}
}

.loading-fullscreen {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba($color: #fff, $alpha: 0.35);
	// backdrop-filter: blur(4px);
}

.ship-method__errors-description {
	padding: 4px;
	p {
		margin-bottom: 4px;
	}
	ul {
		padding: 0 0 0 15px;
		margin: 0;
		li {
			margin-bottom: 2px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.price-advisor__price-column {
	padding: 0 !important;
	table,
	td,
	th {
		border: 0 !important;
	}
	th {
		width: 50%;
		font-weight: 500;
	}
	td {
		width: 50%;
	}
}

.checkbox-group {
	display: flex;
	align-items: center;
	justify-content: center;
	input {
		margin: 0;
		margin-right: 6px;
	}
}

.react-tool-tip {
	margin-left: 6px;
	display: inline-block;
	& > i {
		color: gray;
		cursor: pointer;
	}
	&__content {
		font-weight: 400;
	}
	&__table {
		width: 300px;
		& > tbody > tr {
			& > th {
				width: 30%;
				padding: 6px;
			}
			& > td {
				width: 70%;
				padding: 3px 6px;
			}
		}
	}
}

[class*=" icon-"],
[class^="icon-"] {
	font-size: 13px;
}

.background-color-unset {
	background-color: transparent !important;
}
