.green {
	color: #198964 !important;
}

.red {
	// color: #891919 !important;
	color: red !important;
}

.lightgreen{
	color:#32CD32 !important;
}

.blue {
	color: #1e88e5 !important;
}

.purple {
	color: #ca84fb !important;
}

.orange {
	color: #ff7043 !important;
}

.pink {
	color: #ca84fb !important;
}

/* ------------------------------------------------------------------------------
 *
 *  # Color system
 *
 *  Keep all color classes in a separate file as it's quite heavy and still optional
 *
 *  Version: 1.1
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.0
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Custom color system
*
*  Custom color system styles, includes background, border and text colors
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */
.bg-primary {
	background-color: #2196f3;
	border-color: #2196f3;
	color: #fff;
}

.bg-primary-300 {
	background-color: #64b5f6;
	border-color: #64b5f6;
	color: #fff;
}

.bg-primary-400 {
	background-color: #42a5f5;
	border-color: #42a5f5;
	color: #fff;
}

.bg-primary-600 {
	background-color: #1e88e5;
	border-color: #1e88e5;
	color: #fff;
}

.bg-primary-700 {
	background-color: #1976d2;
	border-color: #1976d2;
	color: #fff;
}

.bg-primary-800 {
	background-color: #1565c0;
	border-color: #1565c0;
	color: #fff;
}

.bg-danger {
	background-color: #f44336;
	border-color: #f44336;
	color: #fff;
}

.bg-danger-300 {
	background-color: #e57373;
	border-color: #e57373;
	color: #fff;
}

.bg-danger-400 {
	background-color: #ef5350;
	border-color: #ef5350;
	color: #fff;
}

.bg-danger-600 {
	background-color: #e53935;
	border-color: #e53935;
	color: #fff;
}

.bg-danger-700 {
	background-color: #d32f2f;
	border-color: #d32f2f;
	color: #fff;
}

.bg-danger-800 {
	background-color: #c62828;
	border-color: #c62828;
	color: #fff;
}

.bg-success {
	background-color: #4caf50;
	border-color: #4caf50;
	color: #fff;
}

.bg-success-300 {
	background-color: #81c784;
	border-color: #81c784;
	color: #fff;
}

.bg-success-400 {
	background-color: #66bb6a;
	border-color: #66bb6a;
	color: #fff;
}

.bg-success-600 {
	background-color: #43a047;
	border-color: #43a047;
	color: #fff;
}

.bg-success-700 {
	background-color: #388e3c;
	border-color: #388e3c;
	color: #fff;
}

.bg-success-800 {
	background-color: #2e7d32;
	border-color: #2e7d32;
	color: #fff;
}

.bg-warning {
	background-color: #ff5722;
	border-color: #ff5722;
	color: #fff;
}

.bg-warning-300 {
	background-color: #ff8a65;
	border-color: #ff8a65;
	color: #fff;
}

.bg-warning-400 {
	background-color: #ff7043;
	border-color: #ff7043;
	color: #fff;
}

.bg-warning-600 {
	background-color: #f4511e;
	border-color: #f4511e;
	color: #fff;
}

.bg-warning-700 {
	background-color: #e64a19;
	border-color: #e64a19;
	color: #fff;
}

.bg-warning-800 {
	background-color: #d84315;
	border-color: #d84315;
	color: #fff;
}

.bg-info {
	background-color: #00bcd4;
	border-color: #00bcd4;
	color: #fff;
}

.bg-info-300 {
	background-color: #4dd0e1;
	border-color: #4dd0e1;
	color: #fff;
}

.bg-info-400 {
	background-color: #26c6da;
	border-color: #26c6da;
	color: #fff;
}

.bg-info-600 {
	background-color: #00acc1;
	border-color: #00acc1;
	color: #fff;
}

.bg-info-700 {
	background-color: #0097a7;
	border-color: #0097a7;
	color: #fff;
}

.bg-info-800 {
	background-color: #00838f;
	border-color: #00838f;
	color: #fff;
}

.bg-pink {
	background-color: #e91e63;
	border-color: #e91e63;
	color: #fff;
}

.bg-pink-300 {
	background-color: #f06292;
	border-color: #f06292;
	color: #fff;
}

.bg-pink-400 {
	background-color: #ec407a;
	border-color: #ec407a;
	color: #fff;
}

.bg-pink-600 {
	background-color: #d81b60;
	border-color: #d81b60;
	color: #fff;
}

.bg-pink-700 {
	background-color: #c2185b;
	border-color: #c2185b;
	color: #fff;
}

.bg-pink-800 {
	background-color: #ad1457;
	border-color: #ad1457;
	color: #fff;
}

.bg-violet {
	background-color: #9c27b0;
	border-color: #9c27b0;
	color: #fff;
}

.bg-violet-300 {
	background-color: #ba68c8;
	border-color: #ba68c8;
	color: #fff;
}

.bg-violet-400 {
	background-color: #ab47bc;
	border-color: #ab47bc;
	color: #fff;
}

.bg-violet-600 {
	background-color: #8e24aa;
	border-color: #8e24aa;
	color: #fff;
}

.bg-violet-700 {
	background-color: #7b1fa2;
	border-color: #7b1fa2;
	color: #fff;
}

.bg-violet-800 {
	background-color: #6a1b9a;
	border-color: #6a1b9a;
	color: #fff;
}

.bg-purple {
	background-color: #673ab7;
	border-color: #673ab7;
	color: #fff;
}

.bg-purple-300 {
	background-color: #9575cd;
	border-color: #9575cd;
	color: #fff;
}

.bg-purple-400 {
	background-color: #7e57c2;
	border-color: #7e57c2;
	color: #fff;
}

.bg-purple-600 {
	background-color: #5e35b1;
	border-color: #5e35b1;
	color: #fff;
}

.bg-purple-700 {
	background-color: #512da8;
	border-color: #512da8;
	color: #fff;
}

.bg-purple-800 {
	background-color: #4527a0;
	border-color: #4527a0;
	color: #fff;
}

.bg-indigo {
	background-color: #3f51b5;
	border-color: #3f51b5;
	color: #fff;
}

.bg-indigo-300 {
	background-color: #7986cb;
	border-color: #7986cb;
	color: #fff;
}

.bg-indigo-400 {
	background-color: #5c6bc0;
	border-color: #5c6bc0;
	color: #fff;
}

.bg-indigo-600 {
	background-color: #3949ab;
	border-color: #3949ab;
	color: #fff;
}

.bg-indigo-700 {
	background-color: #303f9f;
	border-color: #303f9f;
	color: #fff;
}

.bg-indigo-800 {
	background-color: #283593;
	border-color: #283593;
	color: #fff;
}

.bg-blue {
	background-color: #03a9f4;
	border-color: #03a9f4;
	color: #fff;
}

.bg-blue-300 {
	background-color: #4fc3f7;
	border-color: #4fc3f7;
	color: #fff;
}

.bg-blue-400 {
	background-color: #29b6f6;
	border-color: #29b6f6;
	color: #fff;
}

.bg-blue-600 {
	background-color: #039be5;
	border-color: #039be5;
	color: #fff;
}

.bg-blue-700 {
	background-color: #0288d1;
	border-color: #0288d1;
	color: #fff;
}

.bg-blue-800 {
	background-color: #0277bd;
	border-color: #0277bd;
	color: #fff;
}

.bg-teal {
	background-color: #009688;
	border-color: #009688;
	color: #fff;
}

.bg-teal-300 {
	background-color: #4db6ac;
	border-color: #4db6ac;
	color: #fff;
}

.bg-teal-400 {
	background-color: #26a69a;
	border-color: #26a69a;
	color: #fff;
}

.bg-teal-600 {
	background-color: #00897b;
	border-color: #00897b;
	color: #fff;
}

.bg-teal-700 {
	background-color: #00796b;
	border-color: #00796b;
	color: #fff;
}

.bg-teal-800 {
	background-color: #00695c;
	border-color: #00695c;
	color: #fff;
}

.bg-green {
	background-color: #8bc34a;
	border-color: #8bc34a;
	color: #fff;
}

.bg-green-300 {
	background-color: #aed581;
	border-color: #aed581;
	color: #fff;
}

.bg-green-400 {
	background-color: #9ccc65;
	border-color: #9ccc65;
	color: #fff;
}

.bg-green-600 {
	background-color: #7cb342;
	border-color: #7cb342;
	color: #fff;
}

.bg-green-700 {
	background-color: #689f38;
	border-color: #689f38;
	color: #fff;
}

.bg-green-800 {
	background-color: #558b2f;
	border-color: #558b2f;
	color: #fff;
}

.bg-orange {
	background-color: #ff9800;
	border-color: #ff9800;
	color: #fff;
}

.bg-orange-300 {
	background-color: #ffb74d;
	border-color: #ffb74d;
	color: #fff;
}

.bg-orange-400 {
	background-color: #ffa726;
	border-color: #ffa726;
	color: #fff;
}

.bg-orange-600 {
	background-color: #fb8c00;
	border-color: #fb8c00;
	color: #fff;
}

.bg-orange-700 {
	background-color: #f57c00;
	border-color: #f57c00;
	color: #fff;
}

.bg-orange-800 {
	background-color: #ef6c00;
	border-color: #ef6c00;
	color: #fff;
}

.bg-brown {
	background-color: #795548;
	border-color: #795548;
	color: #fff;
}

.bg-brown-300 {
	background-color: #a1887f;
	border-color: #a1887f;
	color: #fff;
}

.bg-brown-400 {
	background-color: #8d6e63;
	border-color: #8d6e63;
	color: #fff;
}

.bg-brown-600 {
	background-color: #6d4c41;
	border-color: #6d4c41;
	color: #fff;
}

.bg-brown-700 {
	background-color: #5d4037;
	border-color: #5d4037;
	color: #fff;
}

.bg-brown-800 {
	background-color: #4e342e;
	border-color: #4e342e;
	color: #fff;
}

.bg-grey {
	background-color: #777;
	border-color: #777;
	color: #fff;
}

.bg-grey-300 {
	background-color: #999;
	border-color: #999;
	color: #fff;
}

.bg-grey-400 {
	background-color: #888;
	border-color: #888;
	color: #fff;
}

.bg-grey-600 {
	background-color: #666;
	border-color: #666;
	color: #fff;
}

.bg-grey-700 {
	background-color: #555;
	border-color: #555;
	color: #fff;
}

.bg-grey-800 {
	background-color: #444;
	border-color: #444;
	color: #fff;
}

.bg-slate {
	background-color: #607d8b;
	border-color: #607d8b;
	color: #fff;
}

.bg-slate-300 {
	background-color: #90a4ae;
	border-color: #90a4ae;
	color: #fff;
}

.bg-slate-400 {
	background-color: #78909c;
	border-color: #78909c;
	color: #fff;
}

.bg-slate-600 {
	background-color: #546e7a;
	border-color: #546e7a;
	color: #fff;
}

.bg-slate-700 {
	background-color: #455a64;
	border-color: #455a64;
	color: #fff;
}

.bg-slate-800 {
	background-color: #37474f;
	border-color: #37474f;
	color: #fff;
}

.bg-white {
	background-color: #fff;
	color: #333333;
}

.alpha-primary {
	background-color: #e3f2fd;
	border-color: #1e88e5;
}

.alpha-danger {
	background-color: #ffebee;
	border-color: #e53935;
}

.alpha-success {
	background-color: #e8f5e9;
	border-color: #43a047;
}

.alpha-warning {
	background-color: #fbe9e7;
	border-color: #f4511e;
}

.alpha-info {
	background-color: #e0f7fa;
	border-color: #00acc1;
}

.alpha-pink {
	background-color: #fce4ec;
	border-color: #d81b60;
}

.alpha-violet {
	background-color: #f3e5f5;
	border-color: #8e24aa;
}

.alpha-purple {
	background-color: #ede7f6;
	border-color: #5e35b1;
}

.alpha-indigo {
	background-color: #e8eaf6;
	border-color: #3949ab;
}

.alpha-blue {
	background-color: #e1f5fe;
	border-color: #039be5;
}

.alpha-teal {
	background-color: #e0f2f1;
	border-color: #00897b;
}

.alpha-green {
	background-color: #f1f8e9;
	border-color: #7cb342;
}

.alpha-orange {
	background-color: #fff3e0;
	border-color: #fb8c00;
}

.alpha-brown {
	background-color: #efebe9;
	border-color: #6d4c41;
}

.alpha-grey {
	background-color: #fafafa;
	border-color: #666;
}

.alpha-slate {
	background-color: #eceff1;
	border-color: #546e7a;
}

.border-primary {
	border-color: #2196f3;
}

.border-primary-300 {
	border-color: #64b5f6;
}

.border-primary-400 {
	border-color: #42a5f5;
}

.border-primary-600 {
	border-color: #1e88e5;
}

.border-primary-700 {
	border-color: #1976d2;
}

.border-primary-800 {
	border-color: #1565c0;
}

.border-danger {
	border-color: #f44336;
}

.border-danger-300 {
	border-color: #e57373;
}

.border-danger-400 {
	border-color: #ef5350;
}

.border-danger-600 {
	border-color: #e53935;
}

.border-danger-700 {
	border-color: #d32f2f;
}

.border-danger-800 {
	border-color: #c62828;
}

.border-success {
	border-color: #4caf50;
}

.border-success-300 {
	border-color: #81c784;
}

.border-success-400 {
	border-color: #66bb6a;
}

.border-success-600 {
	border-color: #43a047;
}

.border-success-700 {
	border-color: #388e3c;
}

.border-success-800 {
	border-color: #2e7d32;
}

.border-warning {
	border-color: #ff5722;
}

.border-warning-300 {
	border-color: #ff8a65;
}

.border-warning-400 {
	border-color: #ff7043;
}

.border-warning-600 {
	border-color: #f4511e;
}

.border-warning-700 {
	border-color: #e64a19;
}

.border-warning-800 {
	border-color: #d84315;
}

.border-info {
	border-color: #00bcd4;
}

.border-info-300 {
	border-color: #4dd0e1;
}

.border-info-400 {
	border-color: #26c6da;
}

.border-info-600 {
	border-color: #00acc1;
}

.border-info-700 {
	border-color: #0097a7;
}

.border-info-800 {
	border-color: #00838f;
}

.border-pink {
	border-color: #e91e63;
}

.border-pink-300 {
	border-color: #f06292;
}

.border-pink-400 {
	border-color: #ec407a;
}

.border-pink-600 {
	border-color: #d81b60;
}

.border-pink-700 {
	border-color: #c2185b;
}

.border-pink-800 {
	border-color: #ad1457;
}

.border-violet {
	border-color: #9c27b0;
}

.border-violet-300 {
	border-color: #ba68c8;
}

.border-violet-400 {
	border-color: #ab47bc;
}

.border-violet-600 {
	border-color: #8e24aa;
}

.border-violet-700 {
	border-color: #7b1fa2;
}

.border-violet-800 {
	border-color: #6a1b9a;
}

.border-purple {
	border-color: #673ab7;
}

.border-purple-300 {
	border-color: #9575cd;
}

.border-purple-400 {
	border-color: #7e57c2;
}

.border-purple-600 {
	border-color: #5e35b1;
}

.border-purple-700 {
	border-color: #512da8;
}

.border-purple-800 {
	border-color: #4527a0;
}

.border-indigo {
	border-color: #3f51b5;
}

.border-indigo-300 {
	border-color: #7986cb;
}

.border-indigo-400 {
	border-color: #5c6bc0;
}

.border-indigo-600 {
	border-color: #3949ab;
}

.border-indigo-700 {
	border-color: #303f9f;
}

.border-indigo-800 {
	border-color: #283593;
}

.border-blue {
	border-color: #03a9f4;
}

.border-blue-300 {
	border-color: #4fc3f7;
}

.border-blue-400 {
	border-color: #29b6f6;
}

.border-blue-600 {
	border-color: #039be5;
}

.border-blue-700 {
	border-color: #0288d1;
}

.border-blue-800 {
	border-color: #0277bd;
}

.border-teal {
	border-color: #009688;
}

.border-teal-300 {
	border-color: #4db6ac;
}

.border-teal-400 {
	border-color: #26a69a;
}

.border-teal-600 {
	border-color: #00897b;
}

.border-teal-700 {
	border-color: #00796b;
}

.border-teal-800 {
	border-color: #00695c;
}

.border-green {
	border-color: #8bc34a;
}

.border-green-300 {
	border-color: #aed581;
}

.border-green-400 {
	border-color: #9ccc65;
}

.border-green-600 {
	border-color: #7cb342;
}

.border-green-700 {
	border-color: #689f38;
}

.border-green-800 {
	border-color: #558b2f;
}

.border-orange {
	border-color: #ff9800;
}

.border-orange-300 {
	border-color: #ffb74d;
}

.border-orange-400 {
	border-color: #ffa726;
}

.border-orange-600 {
	border-color: #fb8c00;
}

.border-orange-700 {
	border-color: #f57c00;
}

.border-orange-800 {
	border-color: #ef6c00;
}

.border-brown {
	border-color: #795548;
}

.border-brown-300 {
	border-color: #a1887f;
}

.border-brown-400 {
	border-color: #8d6e63;
}

.border-brown-600 {
	border-color: #6d4c41;
}

.border-brown-700 {
	border-color: #5d4037;
}

.border-brown-800 {
	border-color: #4e342e;
}

.border-grey {
	border-color: #777;
}

.border-grey-300 {
	border-color: #999;
}

.border-grey-400 {
	border-color: #888;
}

.border-grey-600 {
	border-color: #666;
}

.border-grey-700 {
	border-color: #555;
}

.border-grey-800 {
	border-color: #444;
}

.border-slate {
	border-color: #607d8b;
}

.border-slate-300 {
	border-color: #90a4ae;
}

.border-slate-400 {
	border-color: #78909c;
}

.border-slate-600 {
	border-color: #546e7a;
}

.border-slate-700 {
	border-color: #455a64;
}

.border-slate-800 {
	border-color: #37474f;
}

.border-white {
	border-color: #fff;
}

.border-default {
	border-color: #ddd;
}

.border-top-primary {
	border-top-color: #2196f3;
}

.border-top-primary-300 {
	border-top-color: #64b5f6;
}

.border-top-primary-400 {
	border-top-color: #42a5f5;
}

.border-top-primary-600 {
	border-top-color: #1e88e5;
}

.border-top-primary-700 {
	border-top-color: #1976d2;
}

.border-top-primary-800 {
	border-top-color: #1565c0;
}

.border-top-danger {
	border-top-color: #f44336;
}

.border-top-danger-300 {
	border-top-color: #e57373;
}

.border-top-danger-400 {
	border-top-color: #ef5350;
}

.border-top-danger-600 {
	border-top-color: #e53935;
}

.border-top-danger-700 {
	border-top-color: #d32f2f;
}

.border-top-danger-800 {
	border-top-color: #c62828;
}

.border-top-success {
	border-top-color: #4caf50;
}

.border-top-success-300 {
	border-top-color: #81c784;
}

.border-top-success-400 {
	border-top-color: #66bb6a;
}

.border-top-success-600 {
	border-top-color: #43a047;
}

.border-top-success-700 {
	border-top-color: #388e3c;
}

.border-top-success-800 {
	border-top-color: #2e7d32;
}

.border-top-warning {
	border-top-color: #ff5722;
}

.border-top-warning-300 {
	border-top-color: #ff8a65;
}

.border-top-warning-400 {
	border-top-color: #ff7043;
}

.border-top-warning-600 {
	border-top-color: #f4511e;
}

.border-top-warning-700 {
	border-top-color: #e64a19;
}

.border-top-warning-800 {
	border-top-color: #d84315;
}

.border-top-info {
	border-top-color: #00bcd4;
}

.border-top-info-300 {
	border-top-color: #4dd0e1;
}

.border-top-info-400 {
	border-top-color: #26c6da;
}

.border-top-info-600 {
	border-top-color: #00acc1;
}

.border-top-info-700 {
	border-top-color: #0097a7;
}

.border-top-info-800 {
	border-top-color: #00838f;
}

.border-top-pink {
	border-top-color: #e91e63;
}

.border-top-pink-300 {
	border-top-color: #f06292;
}

.border-top-pink-400 {
	border-top-color: #ec407a;
}

.border-top-pink-600 {
	border-top-color: #d81b60;
}

.border-top-pink-700 {
	border-top-color: #c2185b;
}

.border-top-pink-800 {
	border-top-color: #ad1457;
}

.border-top-violet {
	border-top-color: #9c27b0;
}

.border-top-violet-300 {
	border-top-color: #ba68c8;
}

.border-top-violet-400 {
	border-top-color: #ab47bc;
}

.border-top-violet-600 {
	border-top-color: #8e24aa;
}

.border-top-violet-700 {
	border-top-color: #7b1fa2;
}

.border-top-violet-800 {
	border-top-color: #6a1b9a;
}

.border-top-purple {
	border-top-color: #673ab7;
}

.border-top-purple-300 {
	border-top-color: #9575cd;
}

.border-top-purple-400 {
	border-top-color: #7e57c2;
}

.border-top-purple-600 {
	border-top-color: #5e35b1;
}

.border-top-purple-700 {
	border-top-color: #512da8;
}

.border-top-purple-800 {
	border-top-color: #4527a0;
}

.border-top-indigo {
	border-top-color: #3f51b5;
}

.border-top-indigo-300 {
	border-top-color: #7986cb;
}

.border-top-indigo-400 {
	border-top-color: #5c6bc0;
}

.border-top-indigo-600 {
	border-top-color: #3949ab;
}

.border-top-indigo-700 {
	border-top-color: #303f9f;
}

.border-top-indigo-800 {
	border-top-color: #283593;
}

.border-top-blue {
	border-top-color: #03a9f4;
}

.border-top-blue-300 {
	border-top-color: #4fc3f7;
}

.border-top-blue-400 {
	border-top-color: #29b6f6;
}

.border-top-blue-600 {
	border-top-color: #039be5;
}

.border-top-blue-700 {
	border-top-color: #0288d1;
}

.border-top-blue-800 {
	border-top-color: #0277bd;
}

.border-top-teal {
	border-top-color: #009688;
}

.border-top-teal-300 {
	border-top-color: #4db6ac;
}

.border-top-teal-400 {
	border-top-color: #26a69a;
}

.border-top-teal-600 {
	border-top-color: #00897b;
}

.border-top-teal-700 {
	border-top-color: #00796b;
}

.border-top-teal-800 {
	border-top-color: #00695c;
}

.border-top-green {
	border-top-color: #8bc34a;
}

.border-top-green-300 {
	border-top-color: #aed581;
}

.border-top-green-400 {
	border-top-color: #9ccc65;
}

.border-top-green-600 {
	border-top-color: #7cb342;
}

.border-top-green-700 {
	border-top-color: #689f38;
}

.border-top-green-800 {
	border-top-color: #558b2f;
}

.border-top-orange {
	border-top-color: #ff9800;
}

.border-top-orange-300 {
	border-top-color: #ffb74d;
}

.border-top-orange-400 {
	border-top-color: #ffa726;
}

.border-top-orange-600 {
	border-top-color: #fb8c00;
}

.border-top-orange-700 {
	border-top-color: #f57c00;
}

.border-top-orange-800 {
	border-top-color: #ef6c00;
}

.border-top-brown {
	border-top-color: #795548;
}

.border-top-brown-300 {
	border-top-color: #a1887f;
}

.border-top-brown-400 {
	border-top-color: #8d6e63;
}

.border-top-brown-600 {
	border-top-color: #6d4c41;
}

.border-top-brown-700 {
	border-top-color: #5d4037;
}

.border-top-brown-800 {
	border-top-color: #4e342e;
}

.border-top-grey {
	border-top-color: #777;
}

.border-top-grey-300 {
	border-top-color: #999;
}

.border-top-grey-400 {
	border-top-color: #888;
}

.border-top-grey-600 {
	border-top-color: #666;
}

.border-top-grey-700 {
	border-top-color: #555;
}

.border-top-grey-800 {
	border-top-color: #444;
}

.border-top-slate {
	border-top-color: #607d8b;
}

.border-top-slate-300 {
	border-top-color: #90a4ae;
}

.border-top-slate-400 {
	border-top-color: #78909c;
}

.border-top-slate-600 {
	border-top-color: #546e7a;
}

.border-top-slate-700 {
	border-top-color: #455a64;
}

.border-top-slate-800 {
	border-top-color: #37474f;
}

.border-top-white {
	border-top-color: #fff;
}

.border-bottom-primary {
	border-bottom-color: #2196f3;
}

.border-bottom-primary-300 {
	border-bottom-color: #64b5f6;
}

.border-bottom-primary-400 {
	border-bottom-color: #42a5f5;
}

.border-bottom-primary-600 {
	border-bottom-color: #1e88e5;
}

.border-bottom-primary-700 {
	border-bottom-color: #1976d2;
}

.border-bottom-primary-800 {
	border-bottom-color: #1565c0;
}

.border-bottom-danger {
	border-bottom-color: #f44336;
}

.border-bottom-danger-300 {
	border-bottom-color: #e57373;
}

.border-bottom-danger-400 {
	border-bottom-color: #ef5350;
}

.border-bottom-danger-600 {
	border-bottom-color: #e53935;
}

.border-bottom-danger-700 {
	border-bottom-color: #d32f2f;
}

.border-bottom-danger-800 {
	border-bottom-color: #c62828;
}

.border-bottom-success {
	border-bottom-color: #4caf50;
}

.border-bottom-success-300 {
	border-bottom-color: #81c784;
}

.border-bottom-success-400 {
	border-bottom-color: #66bb6a;
}

.border-bottom-success-600 {
	border-bottom-color: #43a047;
}

.border-bottom-success-700 {
	border-bottom-color: #388e3c;
}

.border-bottom-success-800 {
	border-bottom-color: #2e7d32;
}

.border-bottom-warning {
	border-bottom-color: #ff5722;
}

.border-bottom-warning-300 {
	border-bottom-color: #ff8a65;
}

.border-bottom-warning-400 {
	border-bottom-color: #ff7043;
}

.border-bottom-warning-600 {
	border-bottom-color: #f4511e;
}

.border-bottom-warning-700 {
	border-bottom-color: #e64a19;
}

.border-bottom-warning-800 {
	border-bottom-color: #d84315;
}

.border-bottom-info {
	border-bottom-color: #00bcd4;
}

.border-bottom-info-300 {
	border-bottom-color: #4dd0e1;
}

.border-bottom-info-400 {
	border-bottom-color: #26c6da;
}

.border-bottom-info-600 {
	border-bottom-color: #00acc1;
}

.border-bottom-info-700 {
	border-bottom-color: #0097a7;
}

.border-bottom-info-800 {
	border-bottom-color: #00838f;
}

.border-bottom-pink {
	border-bottom-color: #e91e63;
}

.border-bottom-pink-300 {
	border-bottom-color: #f06292;
}

.border-bottom-pink-400 {
	border-bottom-color: #ec407a;
}

.border-bottom-pink-600 {
	border-bottom-color: #d81b60;
}

.border-bottom-pink-700 {
	border-bottom-color: #c2185b;
}

.border-bottom-pink-800 {
	border-bottom-color: #ad1457;
}

.border-bottom-violet {
	border-bottom-color: #9c27b0;
}

.border-bottom-violet-300 {
	border-bottom-color: #ba68c8;
}

.border-bottom-violet-400 {
	border-bottom-color: #ab47bc;
}

.border-bottom-violet-600 {
	border-bottom-color: #8e24aa;
}

.border-bottom-violet-700 {
	border-bottom-color: #7b1fa2;
}

.border-bottom-violet-800 {
	border-bottom-color: #6a1b9a;
}

.border-bottom-purple {
	border-bottom-color: #673ab7;
}

.border-bottom-purple-300 {
	border-bottom-color: #9575cd;
}

.border-bottom-purple-400 {
	border-bottom-color: #7e57c2;
}

.border-bottom-purple-600 {
	border-bottom-color: #5e35b1;
}

.border-bottom-purple-700 {
	border-bottom-color: #512da8;
}

.border-bottom-purple-800 {
	border-bottom-color: #4527a0;
}

.border-bottom-indigo {
	border-bottom-color: #3f51b5;
}

.border-bottom-indigo-300 {
	border-bottom-color: #7986cb;
}

.border-bottom-indigo-400 {
	border-bottom-color: #5c6bc0;
}

.border-bottom-indigo-600 {
	border-bottom-color: #3949ab;
}

.border-bottom-indigo-700 {
	border-bottom-color: #303f9f;
}

.border-bottom-indigo-800 {
	border-bottom-color: #283593;
}

.border-bottom-blue {
	border-bottom-color: #03a9f4;
}

.border-bottom-blue-300 {
	border-bottom-color: #4fc3f7;
}

.border-bottom-blue-400 {
	border-bottom-color: #29b6f6;
}

.border-bottom-blue-600 {
	border-bottom-color: #039be5;
}

.border-bottom-blue-700 {
	border-bottom-color: #0288d1;
}

.border-bottom-blue-800 {
	border-bottom-color: #0277bd;
}

.border-bottom-teal {
	border-bottom-color: #009688;
}

.border-bottom-teal-300 {
	border-bottom-color: #4db6ac;
}

.border-bottom-teal-400 {
	border-bottom-color: #26a69a;
}

.border-bottom-teal-600 {
	border-bottom-color: #00897b;
}

.border-bottom-teal-700 {
	border-bottom-color: #00796b;
}

.border-bottom-teal-800 {
	border-bottom-color: #00695c;
}

.border-bottom-green {
	border-bottom-color: #8bc34a;
}

.border-bottom-green-300 {
	border-bottom-color: #aed581;
}

.border-bottom-green-400 {
	border-bottom-color: #9ccc65;
}

.border-bottom-green-600 {
	border-bottom-color: #7cb342;
}

.border-bottom-green-700 {
	border-bottom-color: #689f38;
}

.border-bottom-green-800 {
	border-bottom-color: #558b2f;
}

.border-bottom-orange {
	border-bottom-color: #ff9800;
}

.border-bottom-orange-300 {
	border-bottom-color: #ffb74d;
}

.border-bottom-orange-400 {
	border-bottom-color: #ffa726;
}

.border-bottom-orange-600 {
	border-bottom-color: #fb8c00;
}

.border-bottom-orange-700 {
	border-bottom-color: #f57c00;
}

.border-bottom-orange-800 {
	border-bottom-color: #ef6c00;
}

.border-bottom-brown {
	border-bottom-color: #795548;
}

.border-bottom-brown-300 {
	border-bottom-color: #a1887f;
}

.border-bottom-brown-400 {
	border-bottom-color: #8d6e63;
}

.border-bottom-brown-600 {
	border-bottom-color: #6d4c41;
}

.border-bottom-brown-700 {
	border-bottom-color: #5d4037;
}

.border-bottom-brown-800 {
	border-bottom-color: #4e342e;
}

.border-bottom-grey {
	border-bottom-color: #777;
}

.border-bottom-grey-300 {
	border-bottom-color: #999;
}

.border-bottom-grey-400 {
	border-bottom-color: #888;
}

.border-bottom-grey-600 {
	border-bottom-color: #666;
}

.border-bottom-grey-700 {
	border-bottom-color: #555;
}

.border-bottom-grey-800 {
	border-bottom-color: #444;
}

.border-bottom-slate {
	border-bottom-color: #607d8b;
}

.border-bottom-slate-300 {
	border-bottom-color: #90a4ae;
}

.border-bottom-slate-400 {
	border-bottom-color: #78909c;
}

.border-bottom-slate-600 {
	border-bottom-color: #546e7a;
}

.border-bottom-slate-700 {
	border-bottom-color: #455a64;
}

.border-bottom-slate-800 {
	border-bottom-color: #37474f;
}

.border-bottom-white {
	border-bottom-color: #fff;
}

.border-left-primary {
	border-left-color: #2196f3;
}

.border-left-primary-300 {
	border-left-color: #64b5f6;
}

.border-left-primary-400 {
	border-left-color: #42a5f5;
}

.border-left-primary-600 {
	border-left-color: #1e88e5;
}

.border-left-primary-700 {
	border-left-color: #1976d2;
}

.border-left-primary-800 {
	border-left-color: #1565c0;
}

.border-left-danger {
	border-left-color: #f44336;
}

.border-left-danger-300 {
	border-left-color: #e57373;
}

.border-left-danger-400 {
	border-left-color: #ef5350;
}

.border-left-danger-600 {
	border-left-color: #e53935;
}

.border-left-danger-700 {
	border-left-color: #d32f2f;
}

.border-left-danger-800 {
	border-left-color: #c62828;
}

.border-left-success {
	border-left-color: #4caf50;
}

.border-left-success-300 {
	border-left-color: #81c784;
}

.border-left-success-400 {
	border-left-color: #66bb6a;
}

.border-left-success-600 {
	border-left-color: #43a047;
}

.border-left-success-700 {
	border-left-color: #388e3c;
}

.border-left-success-800 {
	border-left-color: #2e7d32;
}

.border-left-warning {
	border-left-color: #ff5722;
}

.border-left-warning-300 {
	border-left-color: #ff8a65;
}

.border-left-warning-400 {
	border-left-color: #ff7043;
}

.border-left-warning-600 {
	border-left-color: #f4511e;
}

.border-left-warning-700 {
	border-left-color: #e64a19;
}

.border-left-warning-800 {
	border-left-color: #d84315;
}

.border-left-info {
	border-left-color: #00bcd4;
}

.border-left-info-300 {
	border-left-color: #4dd0e1;
}

.border-left-info-400 {
	border-left-color: #26c6da;
}

.border-left-info-600 {
	border-left-color: #00acc1;
}

.border-left-info-700 {
	border-left-color: #0097a7;
}

.border-left-info-800 {
	border-left-color: #00838f;
}

.border-left-pink {
	border-left-color: #e91e63;
}

.border-left-pink-300 {
	border-left-color: #f06292;
}

.border-left-pink-400 {
	border-left-color: #ec407a;
}

.border-left-pink-600 {
	border-left-color: #d81b60;
}

.border-left-pink-700 {
	border-left-color: #c2185b;
}

.border-left-pink-800 {
	border-left-color: #ad1457;
}

.border-left-violet {
	border-left-color: #9c27b0;
}

.border-left-violet-300 {
	border-left-color: #ba68c8;
}

.border-left-violet-400 {
	border-left-color: #ab47bc;
}

.border-left-violet-600 {
	border-left-color: #8e24aa;
}

.border-left-violet-700 {
	border-left-color: #7b1fa2;
}

.border-left-violet-800 {
	border-left-color: #6a1b9a;
}

.border-left-purple {
	border-left-color: #673ab7;
}

.border-left-purple-300 {
	border-left-color: #9575cd;
}

.border-left-purple-400 {
	border-left-color: #7e57c2;
}

.border-left-purple-600 {
	border-left-color: #5e35b1;
}

.border-left-purple-700 {
	border-left-color: #512da8;
}

.border-left-purple-800 {
	border-left-color: #4527a0;
}

.border-left-indigo {
	border-left-color: #3f51b5;
}

.border-left-indigo-300 {
	border-left-color: #7986cb;
}

.border-left-indigo-400 {
	border-left-color: #5c6bc0;
}

.border-left-indigo-600 {
	border-left-color: #3949ab;
}

.border-left-indigo-700 {
	border-left-color: #303f9f;
}

.border-left-indigo-800 {
	border-left-color: #283593;
}

.border-left-blue {
	border-left-color: #03a9f4;
}

.border-left-blue-300 {
	border-left-color: #4fc3f7;
}

.border-left-blue-400 {
	border-left-color: #29b6f6;
}

.border-left-blue-600 {
	border-left-color: #039be5;
}

.border-left-blue-700 {
	border-left-color: #0288d1;
}

.border-left-blue-800 {
	border-left-color: #0277bd;
}

.border-left-teal {
	border-left-color: #009688;
}

.border-left-teal-300 {
	border-left-color: #4db6ac;
}

.border-left-teal-400 {
	border-left-color: #26a69a;
}

.border-left-teal-600 {
	border-left-color: #00897b;
}

.border-left-teal-700 {
	border-left-color: #00796b;
}

.border-left-teal-800 {
	border-left-color: #00695c;
}

.border-left-green {
	border-left-color: #8bc34a;
}

.border-left-green-300 {
	border-left-color: #aed581;
}

.border-left-green-400 {
	border-left-color: #9ccc65;
}

.border-left-green-600 {
	border-left-color: #7cb342;
}

.border-left-green-700 {
	border-left-color: #689f38;
}

.border-left-green-800 {
	border-left-color: #558b2f;
}

.border-left-orange {
	border-left-color: #ff9800;
}

.border-left-orange-300 {
	border-left-color: #ffb74d;
}

.border-left-orange-400 {
	border-left-color: #ffa726;
}

.border-left-orange-600 {
	border-left-color: #fb8c00;
}

.border-left-orange-700 {
	border-left-color: #f57c00;
}

.border-left-orange-800 {
	border-left-color: #ef6c00;
}

.border-left-brown {
	border-left-color: #795548;
}

.border-left-brown-300 {
	border-left-color: #a1887f;
}

.border-left-brown-400 {
	border-left-color: #8d6e63;
}

.border-left-brown-600 {
	border-left-color: #6d4c41;
}

.border-left-brown-700 {
	border-left-color: #5d4037;
}

.border-left-brown-800 {
	border-left-color: #4e342e;
}

.border-left-grey {
	border-left-color: #777;
}

.border-left-grey-300 {
	border-left-color: #999;
}

.border-left-grey-400 {
	border-left-color: #888;
}

.border-left-grey-600 {
	border-left-color: #666;
}

.border-left-grey-700 {
	border-left-color: #555;
}

.border-left-grey-800 {
	border-left-color: #444;
}

.border-left-slate {
	border-left-color: #607d8b;
}

.border-left-slate-300 {
	border-left-color: #90a4ae;
}

.border-left-slate-400 {
	border-left-color: #78909c;
}

.border-left-slate-600 {
	border-left-color: #546e7a;
}

.border-left-slate-700 {
	border-left-color: #455a64;
}

.border-left-slate-800 {
	border-left-color: #37474f;
}

.border-left-white {
	border-left-color: #fff;
}

.border-right-primary {
	border-right-color: #2196f3;
}

.border-right-primary-300 {
	border-right-color: #64b5f6;
}

.border-right-primary-400 {
	border-right-color: #42a5f5;
}

.border-right-primary-600 {
	border-right-color: #1e88e5;
}

.border-right-primary-700 {
	border-right-color: #1976d2;
}

.border-right-primary-800 {
	border-right-color: #1565c0;
}

.border-right-danger {
	border-right-color: #f44336;
}

.border-right-danger-300 {
	border-right-color: #e57373;
}

.border-right-danger-400 {
	border-right-color: #ef5350;
}

.border-right-danger-600 {
	border-right-color: #e53935;
}

.border-right-danger-700 {
	border-right-color: #d32f2f;
}

.border-right-danger-800 {
	border-right-color: #c62828;
}

.border-right-success {
	border-right-color: #4caf50;
}

.border-right-success-300 {
	border-right-color: #81c784;
}

.border-right-success-400 {
	border-right-color: #66bb6a;
}

.border-right-success-600 {
	border-right-color: #43a047;
}

.border-right-success-700 {
	border-right-color: #388e3c;
}

.border-right-success-800 {
	border-right-color: #2e7d32;
}

.border-right-warning {
	border-right-color: #ff5722;
}

.border-right-warning-300 {
	border-right-color: #ff8a65;
}

.border-right-warning-400 {
	border-right-color: #ff7043;
}

.border-right-warning-600 {
	border-right-color: #f4511e;
}

.border-right-warning-700 {
	border-right-color: #e64a19;
}

.border-right-warning-800 {
	border-right-color: #d84315;
}

.border-right-info {
	border-right-color: #00bcd4;
}

.border-right-info-300 {
	border-right-color: #4dd0e1;
}

.border-right-info-400 {
	border-right-color: #26c6da;
}

.border-right-info-600 {
	border-right-color: #00acc1;
}

.border-right-info-700 {
	border-right-color: #0097a7;
}

.border-right-info-800 {
	border-right-color: #00838f;
}

.border-right-pink {
	border-right-color: #e91e63;
}

.border-right-pink-300 {
	border-right-color: #f06292;
}

.border-right-pink-400 {
	border-right-color: #ec407a;
}

.border-right-pink-600 {
	border-right-color: #d81b60;
}

.border-right-pink-700 {
	border-right-color: #c2185b;
}

.border-right-pink-800 {
	border-right-color: #ad1457;
}

.border-right-violet {
	border-right-color: #9c27b0;
}

.border-right-violet-300 {
	border-right-color: #ba68c8;
}

.border-right-violet-400 {
	border-right-color: #ab47bc;
}

.border-right-violet-600 {
	border-right-color: #8e24aa;
}

.border-right-violet-700 {
	border-right-color: #7b1fa2;
}

.border-right-violet-800 {
	border-right-color: #6a1b9a;
}

.border-right-purple {
	border-right-color: #673ab7;
}

.border-right-purple-300 {
	border-right-color: #9575cd;
}

.border-right-purple-400 {
	border-right-color: #7e57c2;
}

.border-right-purple-600 {
	border-right-color: #5e35b1;
}

.border-right-purple-700 {
	border-right-color: #512da8;
}

.border-right-purple-800 {
	border-right-color: #4527a0;
}

.border-right-indigo {
	border-right-color: #3f51b5;
}

.border-right-indigo-300 {
	border-right-color: #7986cb;
}

.border-right-indigo-400 {
	border-right-color: #5c6bc0;
}

.border-right-indigo-600 {
	border-right-color: #3949ab;
}

.border-right-indigo-700 {
	border-right-color: #303f9f;
}

.border-right-indigo-800 {
	border-right-color: #283593;
}

.border-right-blue {
	border-right-color: #03a9f4;
}

.border-right-blue-300 {
	border-right-color: #4fc3f7;
}

.border-right-blue-400 {
	border-right-color: #29b6f6;
}

.border-right-blue-600 {
	border-right-color: #039be5;
}

.border-right-blue-700 {
	border-right-color: #0288d1;
}

.border-right-blue-800 {
	border-right-color: #0277bd;
}

.border-right-teal {
	border-right-color: #009688;
}

.border-right-teal-300 {
	border-right-color: #4db6ac;
}

.border-right-teal-400 {
	border-right-color: #26a69a;
}

.border-right-teal-600 {
	border-right-color: #00897b;
}

.border-right-teal-700 {
	border-right-color: #00796b;
}

.border-right-teal-800 {
	border-right-color: #00695c;
}

.border-right-green {
	border-right-color: #8bc34a;
}

.border-right-green-300 {
	border-right-color: #aed581;
}

.border-right-green-400 {
	border-right-color: #9ccc65;
}

.border-right-green-600 {
	border-right-color: #7cb342;
}

.border-right-green-700 {
	border-right-color: #689f38;
}

.border-right-green-800 {
	border-right-color: #558b2f;
}

.border-right-orange {
	border-right-color: #ff9800;
}

.border-right-orange-300 {
	border-right-color: #ffb74d;
}

.border-right-orange-400 {
	border-right-color: #ffa726;
}

.border-right-orange-600 {
	border-right-color: #fb8c00;
}

.border-right-orange-700 {
	border-right-color: #f57c00;
}

.border-right-orange-800 {
	border-right-color: #ef6c00;
}

.border-right-brown {
	border-right-color: #795548;
}

.border-right-brown-300 {
	border-right-color: #a1887f;
}

.border-right-brown-400 {
	border-right-color: #8d6e63;
}

.border-right-brown-600 {
	border-right-color: #6d4c41;
}

.border-right-brown-700 {
	border-right-color: #5d4037;
}

.border-right-brown-800 {
	border-right-color: #4e342e;
}

.border-right-grey {
	border-right-color: #777;
}

.border-right-grey-300 {
	border-right-color: #999;
}

.border-right-grey-400 {
	border-right-color: #888;
}

.border-right-grey-600 {
	border-right-color: #666;
}

.border-right-grey-700 {
	border-right-color: #555;
}

.border-right-grey-800 {
	border-right-color: #444;
}

.border-right-slate {
	border-right-color: #607d8b;
}

.border-right-slate-300 {
	border-right-color: #90a4ae;
}

.border-right-slate-400 {
	border-right-color: #78909c;
}

.border-right-slate-600 {
	border-right-color: #546e7a;
}

.border-right-slate-700 {
	border-right-color: #455a64;
}

.border-right-slate-800 {
	border-right-color: #37474f;
}

.border-right-white {
	border-right-color: #fff;
}

.text-primary,
.text-primary:hover,
.text-primary:focus {
	color: #2196f3 !important;
}

.text-primary-300,
.text-primary-300:hover,
.text-primary-300:focus {
	color: #2196f3 !important;
}

.text-primary-400,
.text-primary-400:hover,
.text-primary-400:focus {
	color: #42a5f5 !important;
}

.text-primary-600,
.text-primary-600:hover,
.text-primary-600:focus {
	color: #1e88e5 !important;
}

.text-primary-700,
.text-primary-700:hover,
.text-primary-700:focus {
	color: #1976d2 !important;
}

.text-primary-800,
.text-primary-800:hover,
.text-primary-800:focus {
	color: #1565c0 !important;
}

.text-danger,
.text-danger:hover,
.text-danger:focus {
	color: #f44336 !important;
}

.text-danger-300,
.text-danger-300:hover,
.text-danger-300:focus {
	color: #e57373 !important;
}

.text-danger-400,
.text-danger-400:hover,
.text-danger-400:focus {
	color: #ef5350 !important;
}

.text-danger-600,
.text-danger-600:hover,
.text-danger-600:focus {
	color: #e53935 !important;
}

.text-danger-700,
.text-danger-700:hover,
.text-danger-700:focus {
	color: #d32f2f !important;
}

.text-danger-800,
.text-danger-800:hover,
.text-danger-800:focus {
	color: #c62828 !important;
}

.text-success,
.text-success:hover,
.text-success:focus {
	color: #4caf50 !important;
}

.text-success-300,
.text-success-300:hover,
.text-success-300:focus {
	color: #81c784 !important;
}

.text-success-400,
.text-success-400:hover,
.text-success-400:focus {
	color: #66bb6a !important;
}

.text-success-600,
.text-success-600:hover,
.text-success-600:focus {
	color: #43a047 !important;
}

.text-success-700,
.text-success-700:hover,
.text-success-700:focus {
	color: #388e3c !important;
}

.text-success-800,
.text-success-800:hover,
.text-success-800:focus {
	color: #2e7d32 !important;
}

.text-warning,
.text-warning:hover,
.text-warning:focus {
	color: #ff5722 !important;
}

.text-warning-300,
.text-warning-300:hover,
.text-warning-300:focus {
	color: #ff8a65 !important;
}

.text-warning-400,
.text-warning-400:hover,
.text-warning-400:focus {
	color: #ff7043 !important;
}

.text-warning-600,
.text-warning-600:hover,
.text-warning-600:focus {
	color: #f4511e !important;
}

.text-warning-700,
.text-warning-700:hover,
.text-warning-700:focus {
	color: #e64a19 !important;
}

.text-warning-800,
.text-warning-800:hover,
.text-warning-800:focus {
	color: #d84315 !important;
}

.text-info,
.text-info:hover,
.text-info:focus {
	color: #00bcd4 !important;
}

.text-info-300,
.text-info-300:hover,
.text-info-300:focus {
	color: #4dd0e1 !important;
}

.text-info-400,
.text-info-400:hover,
.text-info-400:focus {
	color: #26c6da !important;
}

.text-info-600,
.text-info-600:hover,
.text-info-600:focus {
	color: #00acc1 !important;
}

.text-info-700,
.text-info-700:hover,
.text-info-700:focus {
	color: #0097a7 !important;
}

.text-info-800,
.text-info-800:hover,
.text-info-800:focus {
	color: #00838f !important;
}

.text-pink,
.text-pink:hover,
.text-pink:focus {
	color: #e91e63 !important;
}

.text-pink-300,
.text-pink-300:hover,
.text-pink-300:focus {
	color: #f06292 !important;
}

.text-pink-400,
.text-pink-400:hover,
.text-pink-400:focus {
	color: #ec407a !important;
}

.text-pink-600,
.text-pink-600:hover,
.text-pink-600:focus {
	color: #d81b60 !important;
}

.text-pink-700,
.text-pink-700:hover,
.text-pink-700:focus {
	color: #c2185b !important;
}

.text-pink-800,
.text-pink-800:hover,
.text-pink-800:focus {
	color: #ad1457 !important;
}

.text-violet,
.text-violet:hover,
.text-violet:focus {
	color: #9c27b0 !important;
}

.text-violet-300,
.text-violet-300:hover,
.text-violet-300:focus {
	color: #ba68c8 !important;
}

.text-violet-400,
.text-violet-400:hover,
.text-violet-400:focus {
	color: #ab47bc !important;
}

.text-violet-600,
.text-violet-600:hover,
.text-violet-600:focus {
	color: #8e24aa !important;
}

.text-violet-700,
.text-violet-700:hover,
.text-violet-700:focus {
	color: #7b1fa2 !important;
}

.text-violet-800,
.text-violet-800:hover,
.text-violet-800:focus {
	color: #6a1b9a !important;
}

.text-purple,
.text-purple:hover,
.text-purple:focus {
	color: #673ab7 !important;
}

.text-purple-300,
.text-purple-300:hover,
.text-purple-300:focus {
	color: #9575cd !important;
}

.text-purple-400,
.text-purple-400:hover,
.text-purple-400:focus {
	color: #7e57c2 !important;
}

.text-purple-600,
.text-purple-600:hover,
.text-purple-600:focus {
	color: #5e35b1 !important;
}

.text-purple-700,
.text-purple-700:hover,
.text-purple-700:focus {
	color: #512da8 !important;
}

.text-purple-800,
.text-purple-800:hover,
.text-purple-800:focus {
	color: #4527a0 !important;
}

.text-indigo,
.text-indigo:hover,
.text-indigo:focus {
	color: #3f51b5 !important;
}

.text-indigo-300,
.text-indigo-300:hover,
.text-indigo-300:focus {
	color: #7986cb !important;
}

.text-indigo-400,
.text-indigo-400:hover,
.text-indigo-400:focus {
	color: #5c6bc0 !important;
}

.text-indigo-600,
.text-indigo-600:hover,
.text-indigo-600:focus {
	color: #3949ab !important;
}

.text-indigo-700,
.text-indigo-700:hover,
.text-indigo-700:focus {
	color: #303f9f !important;
}

.text-indigo-800,
.text-indigo-800:hover,
.text-indigo-800:focus {
	color: #283593 !important;
}

.text-blue,
.text-blue:hover,
.text-blue:focus {
	color: #03a9f4 !important;
}

.text-blue-300,
.text-blue-300:hover,
.text-blue-300:focus {
	color: #4fc3f7 !important;
}

.text-blue-400,
.text-blue-400:hover,
.text-blue-400:focus {
	color: #29b6f6 !important;
}

.text-blue-600,
.text-blue-600:hover,
.text-blue-600:focus {
	color: #039be5 !important;
}

.text-blue-700,
.text-blue-700:hover,
.text-blue-700:focus {
	color: #0288d1 !important;
}

.text-blue-800,
.text-blue-800:hover,
.text-blue-800:focus {
	color: #0277bd !important;
}

.text-teal,
.text-teal:hover,
.text-teal:focus {
	color: #009688 !important;
}

.text-teal-300,
.text-teal-300:hover,
.text-teal-300:focus {
	color: #4db6ac !important;
}

.text-teal-400,
.text-teal-400:hover,
.text-teal-400:focus {
	color: #26a69a !important;
}

.text-teal-600,
.text-teal-600:hover,
.text-teal-600:focus {
	color: #00897b !important;
}

.text-teal-700,
.text-teal-700:hover,
.text-teal-700:focus {
	color: #00796b !important;
}

.text-teal-800,
.text-teal-800:hover,
.text-teal-800:focus {
	color: #00695c !important;
}

.text-green,
.text-green:hover,
.text-green:focus {
	color: #8bc34a !important;
}

.text-green-300,
.text-green-300:hover,
.text-green-300:focus {
	color: #aed581 !important;
}

.text-green-400,
.text-green-400:hover,
.text-green-400:focus {
	color: #9ccc65 !important;
}

.text-green-600,
.text-green-600:hover,
.text-green-600:focus {
	color: #7cb342 !important;
}

.text-green-700,
.text-green-700:hover,
.text-green-700:focus {
	color: #689f38 !important;
}

.text-green-800,
.text-green-800:hover,
.text-green-800:focus {
	color: #558b2f !important;
}

.text-orange,
.text-orange:hover,
.text-orange:focus {
	color: #ff9800 !important;
}

.text-orange-300,
.text-orange-300:hover,
.text-orange-300:focus {
	color: #ffb74d !important;
}

.text-orange-400,
.text-orange-400:hover,
.text-orange-400:focus {
	color: #ffa726 !important;
}

.text-orange-600,
.text-orange-600:hover,
.text-orange-600:focus {
	color: #fb8c00 !important;
}

.text-orange-700,
.text-orange-700:hover,
.text-orange-700:focus {
	color: #f57c00 !important;
}

.text-orange-800,
.text-orange-800:hover,
.text-orange-800:focus {
	color: #ef6c00 !important;
}

.text-brown,
.text-brown:hover,
.text-brown:focus {
	color: #795548 !important;
}

.text-brown-300,
.text-brown-300:hover,
.text-brown-300:focus {
	color: #a1887f !important;
}

.text-brown-400,
.text-brown-400:hover,
.text-brown-400:focus {
	color: #8d6e63 !important;
}

.text-brown-600,
.text-brown-600:hover,
.text-brown-600:focus {
	color: #6d4c41 !important;
}

.text-brown-700,
.text-brown-700:hover,
.text-brown-700:focus {
	color: #5d4037 !important;
}

.text-brown-800,
.text-brown-800:hover,
.text-brown-800:focus {
	color: #4e342e !important;
}

.text-grey,
.text-grey:hover,
.text-grey:focus {
	color: #777 !important;
}

.text-grey-300,
.text-grey-300:hover,
.text-grey-300:focus {
	color: #999 !important;
}

.text-grey-400,
.text-grey-400:hover,
.text-grey-400:focus {
	color: #888 !important;
}

.text-grey-600,
.text-grey-600:hover,
.text-grey-600:focus {
	color: #666 !important;
}

.text-grey-700,
.text-grey-700:hover,
.text-grey-700:focus {
	color: #555 !important;
}

.text-grey-800,
.text-grey-800:hover,
.text-grey-800:focus {
	color: #444 !important;
}

.text-slate,
.text-slate:hover,
.text-slate:focus {
	color: #607d8b !important;
}

.text-slate-300,
.text-slate-300:hover,
.text-slate-300:focus {
	color: #90a4ae !important;
}

.text-slate-400,
.text-slate-400:hover,
.text-slate-400:focus {
	color: #78909c !important;
}

.text-slate-600,
.text-slate-600:hover,
.text-slate-600:focus {
	color: #546e7a !important;
}

.text-slate-700,
.text-slate-700:hover,
.text-slate-700:focus {
	color: #455a64 !important;
}

.text-slate-800,
.text-slate-800:hover,
.text-slate-800:focus {
	color: #37474f !important;
}

.text-white,
.text-white:hover,
.text-white:focus {
	color: #fff !important;
}

.text-default,
.text-default:hover,
.text-default:focus {
	color: #333333 !important;
}
