.breakeven__suggestion {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 20px 0;
}

.breakeven__price {
	text-align: center;
	span {
		display: block;
	}
	.price {
		font-size: 18px;
		margin-bottom: 2px;
	}
}
