@import "./module.scss";

.panel-custom-span {
	cursor: pointer;
	color: #0aa699;

	& > i {
		margin-left: 4px;
	}

	&.disabled {
		color: grey !important;
		cursor: not-allowed;
	}
}

.meta {
	font-weight: 400;
	white-space: pre-line;
	color: gray;
}

.font-size-12 {
	font-size: 12px !important;
}

.center {
	text-align: center;
}

.platform__icon,
.product__icon {
	display: flex;
	align-items: center;

	& > img {
		width: 28px;
		height: 28px;
		margin-right: 10px;
	}

	& > div {
		& > p {
			margin: 0;
		}
	}
}

//Pagination
.table-pagination {
	padding: 2px 0;

	& > div.left {
		font-size: 13px;
		display: flex;
		align-items: center;
		padding: 0 8px;
	}

	& > div.right {
		display: flex;
		justify-content: flex-end;
		padding-right: 6px;
		text-align: right;
	}
}

.table-pagination {
	position: relative;
	display: flex;
}

.table-pagination > a {
	font-size: 13px;
	display: block;
	box-sizing: border-box;
	min-width: 32px;
	height: 32px;
	padding: 7px;
	text-align: center;
	color: black;
	border-radius: 2px;
}

.table-pagination > a.disabled {
	cursor: unset;
	color: rgb(187, 187, 187);
}

.table-pagination > a:hover {
	background-color: #f5f5f5;
}

.table-pagination > a.prev,
a.next {
	display: flex;
	align-items: center;
	justify-content: center;
	/* color: rgb(187, 187, 187); */
	color: black;
}

.table-pagination > a.active {
	color: white;
	background-color: #455a64;
}

.table-footer {
	display: flex;
	justify-content: space-between;
	padding-top: 6px;

	& > span {
		line-height: 32px;
	}

	& > div.table-pagination {
	}
}

.search-table-footer > tr > td {
	font-size: 13px;
	padding: 6px 10px;
}

.flex {
	display: flex;
}

.duo-form {
	display: flex;
	justify-content: space-between;

	& > div {
		width: 48%;
	}
}

.table-search {
	position: relative;
	margin-bottom: 10px;
	padding: 5px 15px;
	& > i {
		position: absolute;
		top: 15px;
		right: 35px;
	}
}

.break-all {
	word-break: break-all !important;
}

.react-tabs__tab-list {
	padding: 0 8px;
	border-bottom: 1px solid #ddd;
}

.react-tabs__tab--selected {
	border-color: #ddd;
}

.holder {
	display: flex;
	align-items: center;
	img {
		width: 28px;
		height: 28px;
		margin-right: 10px;
	}
	.info {
		& > span {
			font-weight: 500;
			display: block;
		}
		& > .meta {
			font-weight: 400;
		}
	}
}

.padding-0-15 {
	padding: 0 5px;
}

.primary-table-row {
	background-color: #fefcf4 !important;
}

.secondary-table-row {
	background-color: #f8f8f8 !important;
}

.panel-footer-buttons {
}

.radio-group {
	display: flex;
	align-items: center;
	height: 41px;
	& > input {
		margin-right: 8px;
	}
}

.description-group {
	display: flex;
	align-items: center;
	& > img {
		width: 16px;
		height: 16px;
		margin-right: 10px;
	}
	& > .info {
		text-align: left;
		& > span {
			font-size: 12px;
			display: block;
		}
		& > .meta {
			font-size: 12px;
		}
	}
}

.search-field {
	@for $i from 0 through 12 {
		@each $size in "xs", "md", "lg", "sm" {
			.col-#{$size}-#{$i} {
				padding: 0 10px;
			}
		}
	}
}

.search-section-title {
	margin-bottom: 6px;
}

.status-indicator {
	display: flex;
	align-items: center;
	& > i {
		margin-right: 4px;
	}
}

.grey {
	color: gray;
}

.progress {
	position: relative;
	display: block;
	overflow: auto;
	width: 100%;
	height: unset;
	margin-bottom: 12px;
	padding: 5px;
	text-align: center;
	border-radius: 100px;
	& > .progress-bar {
		height: 17px;
		border-radius: 100px;
	}
	& > .progress-text {
		position: absolute;
		right: 0;
		left: 0;
	}
}

.not-found-container {
	display: flex;
	align-self: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin: 200px 0;
	h3 {
		font-size: 60px;
	}
	h4 {
		font-size: 22px;
	}
	p {
		font-size: 16px;
	}
}

.error-container {
	display: flex;
	align-self: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin: 200px 0;
	h3 {
		font-size: 34px;
		margin-bottom: 24px;
	}
	p {
		font-size: 16px;
	}
}

.table-section-label {
	font-size: 12px;
	font-weight: 400;
	position: relative;
	width: 80%;
	margin-top: 0;
	margin-bottom: 0px;
	padding-bottom: 8px;
	color: #111;
	border-bottom: 1px solid #ebebeb;
	&::after {
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 90px;
		height: 1px;
		content: "";
		background-color: #f09812;
	}
}

.detail-table {
	th,
	td {
		word-break: break-all !important;
		border: 0 !important;
	}
	th {
		font-weight: 600;
		width: 35%;
	}
	td {
		width: 65%;
	}
}

ul.statistics {
	display: flex;
	justify-content: space-evenly;
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		display: flex;
		align-items: center;
		flex-direction: column;

		& > i {
			font-size: 24px;
			margin-bottom: 12px;
			color: gray;
		}

		& > span {
			color: gray;
		}

		& > .dollar {
			color: black;
		}
	}
}

.statistics-v2 {
	display: flex;
	justify-content: space-evenly;
	margin: 8px 0;
	padding: 0;
	list-style: none;

	& > li {
		display: flex;
		align-items: center;
		flex-direction: column;

		& > div {
			display: flex;
			align-items: center;
			margin-bottom: 2px;
			& > i {
				font-size: 16px;
				margin-right: 8px;
				color: #607d8b !important;
			}
			& > span {
				font-size: 14px;
				font-weight: 500;
			}
		}
		& > span {
			color: #999;
		}
	}
}

.black {
	color: black !important;
}

.height-200 {
	height: 200px !important;
}

.dropzone-content {
	cursor: pointer;
	user-select: none;
	text-align: center;
	border: 2px dashed #ccc;
	p {
		margin-bottom: 0;
		padding: 65px 30px;
	}
}

.darkorange {
	color: #eea236 !important;
}

.text-truncate {
	display: inline-block;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;

	-webkit-line-clamp: 3; // max nb lines to show
}

.product-information-dimension {
	display: flex;
	& > label {
		font-weight: 500;
		width: 25%;
		margin-bottom: 0;
	}
	& > span {
		width: 25%;
	}
}

input.form-control[type="file"] {
	padding: 4px 12px !important;
}

.product-feedback {
	padding: 10px 0;

	&__rating {
		& > ul {
			margin: 0;
			padding: 0;
			list-style: none;
			& > li {
				display: inline-block;
				margin-right: 1px;
				& > i {
					color: lightgray;
					&.active {
						color: black;
					}
				}
			}
		}
	}

	&__date,
	&__verified {
		font-weight: 500;
		margin-left: 6px;
		color: gray;
	}

	&__content {
		margin-bottom: 0;
	}

	&__check {
		font-weight: 500;
		margin-left: 6px;
		color: green !important;
	}

	&__reasons {
		margin: 0;
		margin-top: 12px;
		padding: 0;
		list-style: none;
		& > li {
			display: inline-block;
			margin-right: 6px;
			& > span {
				font-weight: 500;
				padding: 1px 5px;
				cursor: pointer;
				transition: background-color 0.2s ease-in-out;
			}
		}
	}

	&__action-bar {
		padding: 10px 10px;
		text-align: right;
	}
}

// .table {
// 	&__search {
// 		position: relative;
// 		margin-bottom: 15px;
// 		padding: 0 15px 0;
// 		& > input {
// 			padding-left: 32px;
// 		}
// 		& > i {
// 			position: absolute;
// 			top: 0;
// 			bottom: 0;
// 			padding: 10px;
// 		}
// 	}
// }

.listing-search-radio {
	margin: 6px 0 20px;
	div {
		display: flex !important;
		align-items: center;
	}
	input {
		margin: 0 6px 2px 0 !important;
	}
}

.search-section-label {
	font-size: 12px;
	font-weight: 500;
	font-weight: 400;
	position: relative;
	display: flex;
	align-items: center;
	width: 20%;
	margin-top: 0;
	margin-bottom: 20px;
	padding: 24px 0 4px;
	color: #111;
	border-bottom: 1px solid #ebebeb;

	&::after {
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 90px;
		height: 1px;
		content: "";
		background-color: #f09812;
	}
}

.description-group-xs {
	img {
		width: 16px;
		height: 16px;
	}
}

.height-100 {
	height: 100%;
}

.panel_no-data-warning {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 24px;
}

.panel-search {
	position: relative;
	padding: 0 12px 12px;
	& > input {
		padding-right: 32px;
	}
	& > .search-icon {
		position: absolute;
		top: 0;
		right: 12px;
		bottom: 0;
		padding: 6px 12px;
	}
}

.invoice-return {
	&__ebay-actions {
		padding: 0;
		li {
			display: flex;
			cursor: pointer;
			margin-bottom: 4px;
			input {
				margin-right: 6px;
			}
		}
	}
	&__ebay-message-attributes {
		padding: 0 0 0 18px;
		margin-top: 8px;
		label {
			margin: 0 6px 0 0;
		}
		li {
			margin-bottom: 4px;
		}
	}
}

.panel-guideline {
	// padding: 0 0 0 2px;
	margin: 12px 0 0;
	li {
		margin-bottom: 2px;
	}
}

.relative {
	position: relative !important;
}

.table-meta-list {
	margin: 15px 0;
	li {
		margin-bottom: 4px;
		& > div {
			display: flex;
			& > label {
				width: 120px;
			}
		}
	}
}

.table-cell__table-container {
	padding: 0 !important;
	tbody > tr:first-child {
		th,
		td {
			border-top: 0 !important;
		}
	}
}

.listing-title-mismatch {
	margin: 4px 0;
	& > span {
		margin-right: 1px;
		&.added {
			color: #3c763d;
			background-color: #dff0d8;
		}
		&.removed {
			background-color: #f2dede;
			color: #a94442;
		}
	}
}

.amazon-export-listing__information {
	padding: 0;
	margin: 0;
	list-style: none;
	& > li {
		display: flex;
		& > label {
			margin-bottom: 0;
			width: 90px;
		}
		& > span {
			margin-bottom: 0;
			width: calc(100% - 90px);
		}
	}
}

.info-table {
	& > div {
		display: flex;
		margin-bottom: 2px;
		& > label {
			width: 100px;
			margin: 0 8px 0 0;
		}
		& > span {
			display: block;
			width: calc(100% - 100px);
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.info-table__amazon-content-mismatch {
	& > div {
		margin-bottom: 2px;
		& > label {
			width: 150px;
		}
		& > span, div, a {
			display: block;
			width: calc(100% - 150px);
		}
	}	
}

.margin-12-0 {
	margin: 12px 0;
}

.mismatch-issue-list {
	margin: 2px 0;
	padding: 0 0 0 16px;
	& > li {
		margin-bottom: 6px;
		& > ul {
			margin: 6px 0 0 0;
			padding-left: 24px;
		}
	}
}

.text-overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.amazon-content-mismatch {
	& > div {
		display: flex;
		justify-content: space-evenly;

		& > div {
			width: 50%;
			& > ul {
				padding-left: 16px;
			}
		}
	}

	p {
		margin: 4px 0;
	}
}

.amazon-content-mismatch-modal {
	display: flex;

	& > img {
		width: 31px;
		margin-right: 8px;
	}

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		& > span {
			display: block;
			font-size: 12px;
		}
	}
}

.button-icon-cell {
	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;

		& > i {
			font-size: 10px;
			padding: 6px;
			cursor: pointer;
		}
	}
}

.efc-list {
	margin: 0;
	padding-left: 22px;
}

.amazon-content-mismatch__tag-list {
	padding: 0;
	margin: 0;
	padding-left: 18px;
	// list-style: none;	
	// display: flex;
	&> li {
		margin-bottom: 2px;
		span {
			// display: block;
			// background-color: #0097a7;
			// color: white;
			// padding: 2px 6px;
			// border-radius: 2px;
			// margin: 0 4px 4px 0;
		}
	}
}

#ListingContributionMargin{
    .lightgreen{
		color:#32CD32 !important;
		.ant-input-number-input{
              color:#32CD32;
		}
	}
    
	.red{
		color:red !important;
		.ant-input-number-input{
              color:red;
		}
	}
}

#amzPriceTable{
	.ant-table-thead > tr > th:nth-child(7),
	.ant-table-thead > tr > th:nth-child(3) {
		background-color: #F0F0F0; 
	}
	.ant-table-thead > tr > th:nth-child(4) {
		background-color: #E6FFE6; 
	}
	.ant-table-thead > tr > th:nth-child(6),
	.ant-table-thead > tr > th:nth-child(5) {
		background-color: #FAF0E6; 
	}
	.ant-table-thead > tr > th:nth-child(8) {
		background-color: #DCEFFF; 
	}
	.ant-table-thead > tr > th:nth-child(9) {
		background-color: #E6DCFF; 
	}
	.ant-table-thead > tr > th:nth-child(10) {
		background-color: #E6DCFF; 
	}
}