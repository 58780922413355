.product-pre-launch {
	&__image-list {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			margin: 0 5px 5px 0;
			border: 1px solid #eee;
			padding: 2px;
			img {
				border-radius: 5px;
				width: 62px;
				height: 62px;
			}
		}
	}

	&__suggested-categorization {
		margin: 6px 0 22px;
		label {
			padding-bottom: 6px;
			margin-bottom: 6px;
		}
		th,
		td {
			padding-left: 0 !important;
		}
	}

	&__finalized-categorization {
		margin-bottom: 12px;
		label {
			margin-bottom: 8px;
		}
		div {

		}	
	}
}
