$nav-height: 52px;
$sidebar-width: 240px;
$sidebar-narrow-width: $nav-height;

.navbar-side {
	display: flex;
	align-items: center;
  margin-left: auto;
}

.navbar-env {
  margin-right: 20px;
  font-weight: bold;
  font-size: 24px;
  color: red;
}

.navbar-version	 {
  margin-right: 10px;
  font-weight: bold;
  font-size: 18px;
}

.navbar-user {
	// position: absolute;
	// right: 0;
	// top: 0;
	// bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 25px;
	&> i {
		margin-right: 10px;
	}
	&> div {
		display: flex;
		flex-direction: column;
		&> label {
			margin: 0;
			line-height: 16px;
		}
		&> span {
			line-height: 16px;
		}
	}
}

.navbar {
	position: fixed;
	z-index: 5;
	display: flex;
	width: 100%;
	height: $nav-height;
	border-bottom: 1px solid rgba(230, 230, 230, 0.7);
	background-color: white;
}

.navbar-header {
	width: $sidebar-width;
	height: calc(100% + 1px);
	background-color: #22262e;
}

.navbar-header-narrow {
	width: $sidebar-narrow-width;

	@extend .navbar-header;
}

.navbar-main {
	display: flex;
	padding: 0 6px;
	& > i {
		line-height: $nav-height;
		width: $nav-height;
		height: $nav-height;
		cursor: pointer;
		text-align: center;
		&:hover {
			background-color: #eee;
		}
	}
	& > h3 {
		font-size: 16px;
		font-weight: 400;
		line-height: $nav-height;
		margin: 0;
		margin-left: 6px;
	}
}

.page-container {
	position: absolute;
	top: $nav-height;
	right: 0;
	bottom: 0;
	left: $sidebar-width;
	padding: 12px 0;
}

.page-container-narrow {
	left: $sidebar-narrow-width;

	@extend .page-container;
}

.catalog > span {
	font-size: 10px;
	font-weight: bolder;
	display: block;
	padding: 4px 24px;
	color: #8b91a0;
}

.sidebar {
	position: fixed;
	z-index: 4;
	top: $nav-height;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: $sidebar-width;
	padding: 12px 0;
	background-color: #1b1e24 !important;
}

.sidebar-narrow {
	width: $sidebar-narrow-width;

	@extend .sidebar;
}

.sidebar-list {
	margin: 0;
	padding: 0;
	list-style: none;
	& > li {
		& > a {
			display: flex;
			align-items: center;
			padding: 8px 26px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			text-decoration: none;
			color: #8b91a0;
			& > i {
				width: 24px;
				margin-right: 8px;
				text-align: center;
			}
		}
		&:hover {
			& > a {
				color: white;
			}
		}
	}
}

.sidebar-list-narrow {
	margin: 0;
	padding: 0;
	list-style: none;
	& > li {
		width: $sidebar-narrow-width;
		text-align: center;
		& > a {
			display: block;
			padding: 6px;
			color: white;
		}
		&:hover {
			background-color: #22262e;
		}
	}
}

.dropdown {
	position: relative;
	z-index: 2;
	& > a {
		z-index: 2;
		display: flex;
		justify-content: space-between;
		& > span > i {
			margin-right: 8px;
		}
		& > i {
			line-height: 19px;
		}
	}

	&:hover > ul {
		display: block !important;
	}

	& > ul {
		position: absolute;
		z-index: 2;
		top: 0;
		left: $sidebar-width;
		display: none;
		width: $sidebar-width;
		margin: 0;
		padding: 10px 0;
		background-color: #1b1e24 !important;
	}
}

.dropdown-narrow {
	position: relative;
	z-index: 2;

	&:hover > ul {
		display: block !important;
	}

	& > ul {
		position: absolute;
		z-index: 2;
		top: 0;
		left: $sidebar-narrow-width;
		display: none;
		width: $sidebar-width;
		margin: 0;
		padding: 10px 0;
		text-align: left;
		background-color: #1b1e24 !important;
	}
}

.active-item {
	background-color: #22262e;
	& > a {
		color: white !important;
	}
}

.loading {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menu-title {
	color: gray;
	padding: 6px 0 6px 16px;
	display: block;
	font-weight: 600;
}
