.table {

	&-no-border {
		th, td {
			border: 0 !important;
		}
	}

	&__control-select-option {
		display: block;
		padding: 0 15px;
		list-style: none;
		margin-bottom: 10px;
		& > li {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-right: 8px;
			padding: 2px 12px;
			border-radius: 2px;
			background-color: #eee;
			transition: all 0.3s ease-in-out;
			&:hover {
				background-color: #ddd;
			}
			cursor: pointer;
			& > i {
				margin-left: 6px;
			}
		}
	}

	&__control-bar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
		padding: 0 15px;
		& > .table__search {
			width: 70%;
		}
		& > .table__control-buttons {
			width: 30%;
		}
	}

	&__control-buttons {
		text-align: right;
		& > button {
			margin-left: 5px;
		}
	}

	&__icon-button {
		display: block;
		padding: 2px 4px;
		& > i {
			font-size: 14px;
		}
	}
	&__search {
		position: relative;
		& > input {
			padding-left: 32px;
		}
		& > i {
			position: absolute;
			top: 0;
			bottom: 0;
			padding: 10px;
		}
	}
}
