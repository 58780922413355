.list-feed {
	&__container {
		overflow-y: scroll;
		max-height: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		position: relative;
		padding-bottom: 16px;
		padding-left: 24px;

		&::before {
			position: absolute;
			z-index: 2;
			top: 4px;
			left: 0;
			display: inline-block;
			width: 9px;
			height: 9px;
			content: "";
			border-radius: 50%;
			background-color: #fff;
			background-color: #45748a;
		}

		&::after {
			position: absolute;
			// border-left: 1px solid #45748a;
			// border-right: 1px solid #45748a;
			z-index: 1;
			top: 0.375rem;
			bottom: -0.5rem;
			left: 4px;
			width: 1px;
			content: "";
			background-color: #e6eaee;
		}

		&[class*="border-"]:after,
		&[class*="border-"]:before {
			border-color: inherit;
		}
		&:last-child:after {
			content: none;
		}

		&__success {
			&::before {
				background-color: #4caf50;
			}
		}

		&__danger {
			&::before {
				background-color: #f44336;
			}
		}

		&__primary {
			&::before {
				background-color: #2196f3;
			}
		}

		&__warning {
			&::before {
				background-color: #ff5722;
			}
		}

		&__slate {
			&::before {
				background-color: #607D8B;
			}
		}
	}

	&__loading {
		padding: 12px;
		text-align: center;
	}
}
