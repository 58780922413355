.inbound-shipment {
	&__address {
		& > section {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 12px;

			& > div {
				display: flex;
				flex-direction: column;
				margin-right: 16px;
			}
		}
	}

	&__id {
		display: flex;
		flex-direction: column;

		& > div {
			display: flex;
			flex-direction: column;
			margin-bottom: 16px;
		}
	}
}
