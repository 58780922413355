.blockList_Panel {
    display: flex !important;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px;
}


@media screen and (max-width: 1600px) {
    .blockList_Panel {
        flex-direction: column;
        justify-content: start;
        align-items: baseline;
    }
}


.block-listing-table {
    th:first-child,
    td:first-child {
        padding-left: 20px;
    }
}