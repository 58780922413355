.permission {
	&__click-icon {
		cursor: pointer;
		color: #0aa699;
		margin-left: 8px;
	}

	&__user-box {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		& > div {
			border: 1px solid #ddd;
			padding: 4px 8px;
			width: 230px;
			margin: 0 8px 6px 0;

			& > span {
				display: block;
				word-wrap: break-word;
			}
		}
	}
}
