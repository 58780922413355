.react-select__menu-list {
	border: 1px solid #eee;
}

.react-select__option--is-focused {
	color: black !important;
	background-color: #eee !important;
}

.react-select__group-heading {
	font-size: 10px !important;
	font-weight: 400 !important;
	text-transform: none !important;
	color: #888 !important;
}

.react-select__option--is-selected {
	color: black !important;
	background-color: #eee !important;

}

.react-select-description-group {
	.logo {
		height: 14px;
		width: 14px;
		margin-right: 8px;
	}	
	.info {
		line-height: 14px;
	}
}

.react-select__indicator {
	padding: 0 8px !important;
}

.react-select__control {
	min-height: 0px !important;
}

// & > div > span {
// 	color: black !important;
// 	&.meta {
// 		margin-left: 12px !important;
// 		color: #777777;
// 	}
// }

.react-select__multi-value__label {
	.meta {
		display: none;
	}
}

.react-select__menu-list {
	border: 1px solid #eee;
}

.react-select__option--is-focused {
	color: black !important;
	background-color: #eee !important;
}

.react-select__group-heading {
	font-size: 10px !important;
	font-weight: 400 !important;
	text-transform: none !important;
	color: #888 !important;
}

.react-select__option--is-selected {
	color: black !important;
	background-color: #eee !important;
	
	& > div > span {
		color: black !important;
		&.meta {
			color: #777777;
		}
	}
}

.react-select__indicator-separator {
	width: 0 !important;
}

.react-select__indicator {
	&> svg {
		height: 15px !important;
		width: 15px !important;
	}
}

.react-select__placeholder {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
}
