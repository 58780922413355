.listing-update {
	&__components {
		padding: 4px;
		& > div {
			border-bottom: 1px solid #eee;
			margin-bottom: 8px;
			&:last-child {
				border-bottom: 0;
			}
		}
	}
	&__title {
	}
	&__images {
		& > ul {
			padding: 0;
			margin: 0;
			& > li {
				display: inline-block;
				margin-right: 8px;
				border: 1px solid #eee;
				border-radius: 3px;
				padding: 2px;
				& > img {
					height: 48px;
					width: 48px;
				}
			}
		}
	}
	&__description {
	}
}
