#inventory-allocation {
	#InventoryAllocation {
		span {
			width: 50%;
			display: inline-block;
			text-align: center;
		}
		& > div {
			div {
				border: 1px solid #ccc;
			}
		}
	}
    .SpecificationArea{
      //  flex-wrap: wrap;
        width: 33.3%;
        .InnerSpecificationArea{
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 10px;
            .ant-tag{
                margin-bottom: 5px;
                font-size: 14px;
            }
        }
    }
}
