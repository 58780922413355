.shipping-profile {


	&__details {
	}

	&__group {
		margin-bottom: 24px;
		&:last-child {
			margin-bottom: 0;
		}
		label {
			font-size: 12px;
			font-weight: 400;
			position: relative;
			width: 80%;
			margin-top: 0;
			margin-bottom: 6px;
			padding-bottom: 4px;
			color: #111;
			border-bottom: 1px solid #ebebeb;
			&::after {
				position: absolute;
				bottom: -1px;
				left: 0;
				width: 90px;
				height: 1px;
				content: "";
				background-color: #f09812;
			}
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;
			list-style: none;
		}
	}

	&__list {
		ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;
			list-style: none;
			& > li {
				margin: 0 12px 8px 0;
				& > img {
					width: 16px;
					height: 16px;
					margin-right: 6px;
				}
				& > span {
				}
			}
		}
	}
}
