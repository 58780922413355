.video-drown-menu {
	position: relative;
	& > i {
		font-size: 12px;
		margin-left: 4px;
	}

	& > ul {
		position: absolute;
		z-index: 300 !important;
		top: 34px;
		left: -1px;
		overflow-y: scroll;
		min-width: 120px;
		max-height: 300px;
		padding: 0;
		padding: 0;
		list-style: none;
		text-align: left;
		& > li {
			padding: 6px 12px;
			transition: all 0.2s ease-in-out;
			background-color: white;
			&:hover {
				background-color: #efefef;
			}
		}
	}
}

.video-image-section {
	display: flex;
	& > img {
		width: 200px;
		margin-right: 16px;
		cursor: pointer;
		border-radius: 5px;
	}

	// & > div {
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// }
}

.player-wrapper {
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

.video-close-button {
	position: relative;

	& > .close-button {
		font-size: 10px;
		position: absolute;
		z-index: 10;
		top: -8px;
		right: 7px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 16px;
		height: 16px;
		cursor: pointer;
		color: white;
		border-radius: 50%;
		background-color: #f35958;
	}
}

.video-modal-header {
	display: flex;
	& > img {
		width: 64px;
		margin-right: 8px;
		border-radius: 4px;
	}
}

.video-loading-progress {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	position: absolute;
	background-color: rgba($color: #fff, $alpha: 0.95);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& > span {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 8px;
	}

	& > div {
		width: 400px;
	}
}

.vide-table-sort-header {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	user-select: none;

	& > i {
		margin: auto 0;
	}

	& > .color-light-grey {
		color: lightgray;
	}
}

.video-cc-box {
	user-select: none;
	border: 2px solid #10cfbd;

	& > div {
		padding: 20px 30px;
		display: flex;
		justify-content: center;
		align-items: center;

		& > i {
			cursor: pointer;
			font-size: 18px;
			margin-right: 16px;
		}

		& > p {
			cursor: pointer;
			text-align: center;
			margin-bottom: 0;
		}
	}

	& > textarea {
		border: none;
		overflow: auto;
		outline: none;

		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;

		resize: none; /*remove the resize handle on the bottom right*/

		width: 100%;
		height: 200px;
	}
}

.video-download {
	display: flex;
	justify-content: flex-start;

	& > i {
		font-size: 16px;
		cursor: pointer;
		user-select: none;
	}

	& > div {
		width: 2px;
		background-color: lightgray;
		margin: 0 12px;
	}
}
