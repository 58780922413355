.display-flex {
	display: flex !important;
}

.display-block {
	display: block !important;
}

.display-inline-block {
	display: inline-block !important;
}

.no-padding {
	padding: 0 !important;
}

.height-0 {
	height: 0 !important;
}

.text-center {
	text-align: center !important;
}

@for $i from 0 through 100 {
	.width#{$i} {
		width: percentage(math.div($i, 100)) !important;
	}
	.font-size-#{$i} {
		font-size: #{$i}px !important;
	}
}

@each $attribute in "margin", "padding", "border" {
	@each $direction in "top", "right", "bottom", "left" {
		@for $i from 0 through 50 {
			.#{$attribute}-#{$direction}-#{$i} {
				#{$attribute}-#{$direction}: #{$i}px !important;
			}
		}
	}
}

.distance > * {
	margin: 0 0.6rem 0.6rem 0;
}

.pointer {
	cursor: pointer !important;
}



.no-select {
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
	-webkit-touch-callout: none; /* iOS Safari */
	-khtml-user-select: none; /* Konqueror HTML */
}

.small {
	font-size: 10px !important;
}

.overflow-hidden {
	overflow: hidden;
	width: 100%;
	white-space: pre;
	text-overflow: ellipsis;
}

.display-none {
	display: none !important;
}
