.lihkg-toastify {
	border-radius: 8px;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,.3);
	color: #fff;
	width: 300px;
	background-color: #444;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	// pointer-events: auto;
	z-index: 1;
	margin-bottom: 12px;
	&> header {
		background-color: #333;
		color: #aaa;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 16px;
		&> h6 {
			margin: 0;
			line-height: 14px;
			font-size: 12px;
			font-weight: 400;
			&> i {
				margin: 0 8px 1px 0;
			}
		}
	}
	&> main {
		padding: 12px 16px;
		font-size: 12px;
		color: white;
		&> p {
			margin: 0;
		}
	}
}

.lihkg-toastify-info {
	&> header {
		color: #0090d9;
	}
}

.lihkg-toastify-warn {
	&> header {
		color: #fbc308;
	}
}

.lihkg-toastify-error {
	&> header {
		color: red;
	}
}
