.channel-advisor__create-detail__progress {
	margin-top: 8px;
	padding: 0;
	align-items: center;
	list-style: none;

	&-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 4px;

		span {
			display: block;
		}

		& > .count {
			width: 70px;
			font-size: 18px;
			margin-right: 8px;
		}

		& > .name {
			padding: 14px 20px 8px 20px;
		}

		& > .meta {
			min-height: 34px;
		}
	}
}
