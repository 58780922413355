.listing-sales-tooltip {
	background-color: white;
	padding: 6px 12px;
	border: 1px solid #eee;
	border-radius: 4px;

	label {
		padding-bottom: 4px;
		margin-bottom: 8px;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 220px;
		margin-bottom: 12px;

		&:last-child {
			margin-bottom: 0;
		}

		.description-group {
			img {
				height: 16px;
				width: 16px;
			}
		}
	}
}
