.EFC_DropdownMenu {
	position: relative;
	cursor: pointer;
	display: inline-block;

	&-menu {
		position: absolute;
		width: 140px;
		padding: 6px 0;
		margin: 0;
		text-align: left;
		list-style: none;
		background-color: white;
		right: -8px;
		margin-top: 2px;
		border: 1px solid #eee;
		border-radius: 2px;
		&> li {
			transition: background-color 0.2s ease-in-out;
			&:hover {
				background-color: #eee;
			}
		}
	}

	&-button {
		display: flex;
		align-items: center;
		color: black;
		animation: color 0.3s ease-in-out;
		padding: 6px 12px;
		&:hover {
			color: black;
		}
		i {
			text-align: left;
			margin-right: 12px;
		}
	}
}
