.search_group {
	margin-top: 10px;
	position: relative;

	i {
		&:nth-child(1) {
			position: absolute;
			top: 9px;
			left: 10px;
		}
	}

	& > input {
		width: 100%;
		padding: 6px 30px 6px 30px;
		border: 1px solid #ccc;
		border-radius: 2px;
		box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
		transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -moz-box-shadow ease-in-out 0.15s;
		font-size: 12px;
		line-height: 1.428571429;
		color: #555555;
		background-color: #fff;
	}
}

.expected-margin-navigator {
	margin-left: 8px;
  overflow: scroll;

	& > label {
		font-size: 12px;
		font-weight: 500;
		font-weight: 400;
		position: relative;
		display: flex;
		align-items: center;
		width: 25%;
		margin: 0 0 10px 0;
		padding: 8px 0 4px;
		color: #111;
		border-bottom: 1px solid #ebebeb;

		&::after {
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 90px;
			height: 1px;
			content: "";
			background-color: #f09812;
		}
	}

	& > ul {
		padding: 0;
		white-space: nowrap;
		margin-bottom: 12px;

		& > li {
			cursor: pointer;
			user-select: none;
			display: inline-block;
			list-style: none;
			padding: 8px;
			margin-right: 8px;
			color: black;
			transition: all 0.5s ease-in-out;

			&:hover {
				background-color: #efefef;
				color: black;
			}

			&.active {
				background-color: #efefef;
				color: black;
			}
		}
	}
}
