html,
body,
#root {
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	width: 100%;
	height: 100%;
	background-color: #f9f9f9;
}

a:hover {
	text-decoration: none;
}

//== Common
//
//##

.content-group {
	margin: 10px 20px;
}

.meta {
	font-weight: 400;
	white-space: pre-line;
	color: $gray-light;
}

.buttons {
	& > .btn {
		margin-left: 5px;
	}
}

//== ReactModal
//
//##

.ReactModal__Overlay {
	transition: opacity 200ms ease-in-out;
	opacity: 0;
	outline: none;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

.ReactModal__Content {
	width: 100%;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 24px;
	& > div {
		margin-right: auto;
		margin-left: auto;
	}
}

.error-page > div > main > div {
	background: transparent !important;
}

.text-divider {
	display: flex;
	align-items: center;
	margin: 10px 0;

	& > div {
		width: 100%;
		height: 1px;
		background-color: #eee;
	}

	& > span {
		font-size: 12px;
		width: 300px;
		margin: 0 20px;
		text-align: center;
		color: gray;
	}
}

.line-input {
	width: 100%;
	border: 0;
	border-bottom: 1px solid lightgray;
	padding: 5px;
	&:focus {
		border-bottom: 1px solid #f09812;
		outline: none;
	}
}

.form-submit-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 6px 10px;
}

.form-horizontal-table {
	& > tbody > tr {
		& > th,
		td {
			padding: 6px;
		}
		& > th {
			font-weight: bolder;
		}
	}
}

.form-header {
	padding: 8px 16px;
}

.radio-group {
	display: inline-flex;
	align-items: center;
	margin-right: 12px;
	cursor: pointer;
	& > input {
		margin: 8px 0;
		padding: 0;
	}
	& > span {
		line-height: 18px;
	}
}

.panel-footer-buttons {
	text-align: right;
	& > button {
		margin-left: 8px;
	}
}

.more-span {
	display: flex;
	align-items: center;
	& > i {
		font-size: 10px;
		margin-right: 6px;
	}
}

.panel-disabled-loading,
.panel-disabled-loading-text {
	position: absolute;
	z-index: 5;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	opacity: 0.5;
	background-color: white;
}

.panel-disabled-loading-text {
	z-index: 10;
	color: black;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.table-scroll-wrapper {
	position: relative;
	overflow-y: scroll;
	& > table > thead > tr > th {
		position: sticky;
		z-index: 2;
		top: 0;
		background-color: white;
		&::after {
			position: absolute;
			right: 0;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 2px;
			content: "";
			background-color: #ddd;
		}
	}
}

[class*=" icon-"],
[class^="icon-"] {
	font-size: 12px;
}

.section-label {
	font-size: 12px;
	font-weight: 600;
	position: relative;
	display: flex;
	align-items: center;
	width: 80%;
	margin-top: 0;
	margin-bottom: 6px;
	padding-bottom: 4px;
	color: #111;
	border-bottom: 1px solid #ebebeb;
	&::after {
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 90px;
		height: 1px;
		content: "";
		background-color: #f09812;
	}
}

.btn-dropdown {
	z-index: 30 !important;
	position: relative;
	& > i {
		font-size: 10px;
		margin-left: 8px;
	}
}

.btn-dropdown__menu {
	position: absolute;
	z-index: 30 !important;
	top: 34px;
	left: 0;
	overflow-y: scroll;
	width: 180px;
	max-height: 300px;
	padding: 0;
	padding: 4px 0;
	list-style: none;
	text-align: left;
	border: 1px solid #eee;
	// box-shadow: 0 2px 4px 0 rgb(0 0 0);
	& > li {
		padding: 6px 12px;
		transition: all 0.2s ease-in-out;
		background-color: white;
		&:hover {
			background-color: #efefef;
		}
	}
}

.description-group {
	display: flex;
	align-items: center;
	& > img {
		width: 18px;
		height: 18px;
		margin-right: 10px;
	}
	& > .info {
		text-align: left;
		& > span {
			font-size: 12px;
			display: block;
		}
		& > .meta {
			font-size: 12px;
		}
	}
}
